<template>
  <div>
    <div class="header pt-lg-5">
      <b-container>
        <button class="btn btn-primary btn-sm" @click="goBack">
          <i class="ni ni-bold-left"></i> KEMBALI
        </button>
      </b-container>
    </div>

    <b-container>
      <div class="header-body text-center mb-7">
        <b-row class="justify-content-center">
          <img src="../../../../../public/img/brand/img-logo-color.png" alt="klhk" class="mb-2" />
          <b-col md="12">
            <h2 class="text-black">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="text-lead text-black">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
            </p>
          </b-col>
        </b-row>
        <button class="btn btn-primary" @click="showAnnoncementModal = true">
          LIHAT MEKANISME
        </button>
      </div>
    </b-container>

    <!-- Modal Untuk Menampilkan Lihat Mekanisme -->
    <Modal :show="showAnnoncementModal" modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable" @close="showAnnoncementModal = false" size="xl">
      <template v-slot:header>
        <div class="mdl-header d-flex">
          <img src="../../../../../public/img/brand/img-logo-color.png" alt="klhk" class="mb-2" />
          <h3 class="ml-3">
            Kementerian <br />
            Lingkungan Hidup dan Kehutanan <br />
            Republik Indonesia
          </h3>
        </div>
      </template>
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="overflow-y: scroll; height: 450px">
            <h2 class="text-justify"></h2>
            <h2 class="text-justify">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="mb-2 text-justify">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
              <br />Mekanisme pendaftaran yaitu:
            </p>
            <ol>
              <li>
                Pendaftaran WAJIB dilakukan oleh penanggung jawab usaha dan/atau
                kegiatan (industri), tidak diperbolehkan bagi penyedia peralatan
                & jasa alat sensor pemantauan online.
              </li>
              <li>
                Penanggung jawab usaha dan/atau kegiatan WAJIB melaksanakan
                pendaftaran dan mengisi semua form serta bukti.
              </li>
              <li>
                Pengisian form pendaftaran dan bukti yaitu:
                <ol type="a">
                  <li>Pengisian umum</li>
                  <li>
                    Pengisian dan penyampaian bukti persyaratan identifikasi
                    sumber pencemar
                  </li>
                  <li>Pengisian dan penyampaian bukti persyaratan teknis</li>
                </ol>
              </li>
              <li>
                Hasil jawaban dari permohonan pendaftaran SPARING melalui fitur
                pendaftaran paling lambat dikirim 14 hari kerja setelah
                dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.
              </li>
              <li>
                USER NAME, PASSWORD, UID (User Identification) berupa TANDA
                TERIMA ELEKTRONIK akan dikirimkan ke alamat email yang
                didaftarkan pada form pendaftaran melalui email
                sparing.menlhk@gmail.com jika seluruh isian lengkap dan
                dinyatakan valid oleh Admin SPARING KLHK.
              </li>
              <li>
                Bukti dan data dinyatakan valid melalui bukti persyaratan
                identifikasi sumber pencemar dan persyaratan teknis SPARING
                sesuai dengan Peraturan Menteri Lingkungan Hidup Nomor
                P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air
                Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha
                Dan/Atau Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor
                P.80/MENLHK/SETJEN/KUM.1/10/2019.
              </li>
              <li>
                Jika isian tidak lengkap dan terdapat bukti yang tidak valid
                maka Admin SPARING KLHK akan menyampaikan melalui email yang
                terdaftar. Penanggung jawab usaha dan/atau kegiatan WAJIB
                melengkapi sesuai dengan hasil evaluasi.
              </li>
              <li>
                Kebijakan pengunduran waktu pemasangan dan pengoperasian SPARING
                sesuai Surat Edaran Menteri Lingkungan Hidup dan Kehutanan Nomor
                SE.5/Menlhk/Setjen/KUM.1/6/2020 tidak berlaku lagi.
              </li>
            </ol>

            <br />
            <span>
              Tim SPARING<br />
              Direktorat Pengendalian Pencemaran air<br />
              JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
              Gedung B lantai 5<br />
              Email: sparing.menlhk@gmail.com<br />
              Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam
              pelayanan hari kerja 08.00-15.00 WIB)<br />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button class="bg-primary border-0" @click="showAnnoncementModal = false">
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Modal untuk Tambah Surat Izin -->
    <Modal :show="showFormModal" modalContentClasses="popup-modal-backgrounds" modalClasses="modal-dialog-scrollable"
      size="xl">
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div>
            <div class="p-3">
              <h2 class="text-center">Dokumen Perizinan</h2>
              <div class="form-group row mt-4">
                <label class="col-lg-3 col-form-label form-control-label">Izin Pembuangan Air Limbah ke MediaAir</label>
                <div class="col-lg-9">
                  <base-input name="Izin Pembuangan Air Limbah ke Media Air">
                    <file-input accept="application/pdf" ref="attachment_file_id" @change="
                      uploadFileBlob(form_permits, 'attachment_file_id')
                      "></file-input>
                  </base-input>
                  <small v-if="form_permits.attachment_file_id"
                      class="d-block mt-3 mb-2 text-justify">
                      <a :href="getBlobsFile(form_permits.attachment_file_id)"
                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                  </small>
                </div>
              </div>

              <!-- Pejabat penerbit izin -->
              <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label">Pejabat Penerbit Izin</label>
                <div class="col-lg-9">
                  <base-input name="Pejabat Penerbit Izin" rules="required"
                    v-model="form_permits.issuing_authority"></base-input>
                  <small class="d-block mt--3 text-justify">
                    Contoh Kepala Dinas Modal dan Pelayanan Terpadu Satu Pintu
                    (DPM-PTSP) Provinsi DKI Jakarta
                  </small>
                </div>
              </div>

              <!-- Nomor Izin Pembuangan Air Limbah -->
              <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label mt--2">Nomor Izin Pembuangan Air Limbah
                  (Definitif)</label>
                <div class="col-lg-9">
                  <base-input name="Nomor Izin Pembuangan Air Limbah (Definitif)" rules="required"
                    v-model="form_permits.no"></base-input>
                  <small class="d-block mt--3 text-justify">
                    Diisi nomor izin pembuangan air limbah, bukan surat
                    pemenuhan komitmen. Contoh : Nomor :
                    503/KEP.B75BFFFC-PTSP/2019.
                  </small>
                </div>
              </div>

              <!-- Tanggal Izin Terbit -->
              <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label">Tanggal Izin Terbit</label>
                <div class="col-lg-9">
                  <base-input class="m-0" rules="required" name="Tanggal Izin Terbit">
                    <flat-picker name="Tanggal Izin Terbit" rules="required" slot-scope="{ focus, blur }" @on-open="focus"
                      @on-close="blur" :config="selects.configs.flatpickr" class="form-control datepicker"
                      v-model="form_permits.publication_date"></flat-picker>
                  </base-input>
                </div>
              </div>

              <!-- Berlaku -->
              <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label">Berlaku</label>
                <div class="col-lg-9">
                  <base-input class="m-0" rules="required" name="Berlaku">
                    <flat-picker name="Berlaku" rules="required" slot-scope="{ focus, blur }" @on-open="focus"
                      @on-close="blur" :config="selects.configs.flatpickr" class="form-control datepicker"
                      v-model="form_permits.expiration_date"></flat-picker>
                  </base-input>
                </div>
              </div>

              <div class="row">
                <div class="col text-center">
                  <base-button class="bg-secondary border-0" @click="createPermissionLater()">+ Buat Dokumen</base-button>
                  <base-button class="bg-danger border-0" @click="showFormModal = false">Tutup</base-button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Loading Screen -->
    <Modal :show="isLoading" modalContentClasses="popup-modal-backgrounds" modalClasses="modal-dialog-scrollable"
      size="lg" style="background-color: rgba(0, 0, 0, 0.8)">
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative">
                <img src="img/brand/img-logo-color.png" alt="" />
                <img src="/img/brand/klhk.png" alt="" style="
                    position: absolute;
                    right: 0;
                    top: -200px;
                    opacity: 0.2;
                  " />
                <h1>Sedang Proses...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>


    <!-- Main -->
    <b-container>
      <div class="card">
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
            <div>
              <div class="p-5">

                <div class="form-group row">
                  <label class="col-lg-3 col-form-label form-control-label">Nomor Surat Izin</label>
                  <div class="col-lg-9">
                    <base-input name="Permission Later">
                      <el-select v-model="form.permit_id">
                        <el-option v-for="(item, index) in permissionLaterFormExisting" :key="index"
                          :label="`${item.issuing_authority} NO ${item.no}`" :value="item.id" />
                      </el-select>
                    </base-input>
                    <small class="d-block mt--3 text-justify" style="color:blue;cursor:pointer"
                      @click="showFormModal = true">
                      Belum punya surat izin ? Tambah Surat izin dengan klik link ini
                    </small>
                  </div>
                </div>


                <!-- Sumber air limbah -->
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label form-control-label">Sumber Air Limbah</label>
                  <div class="col-lg-9">
                    <base-input name="Sumber Air Limbah" v-model="form.waste_source"></base-input>
                    <small class="d-block mt--3 text-justify">
                      Air Limbah proses produksi/air limbah utilitas/air limbah
                      domestik/air limbah pencucian atau pengolahan mineral/air
                      limbah penambangan mineral/air limbah pencucian atau
                      pengolahan batu bara/air limbah penambangan batu bara.
                    </small>
                  </div>
                </div>

                <div>
                  <div>
                    <div class="mt-5">
                      <div style="margin-bottom: 30px;display: flex;justify-content: space-between;">
                        <div style="display:flex;flex-direction: column;line-height:0">
                          <h3>Mendaftarkan Titik Penaatan</h3>
                          <span><small>Identifikasi Sumber Pencemar, Persyaratan Teknis, Logger, Sensor</small></span>
                        </div>
                        <base-button size="md" type="secondary" @click="showFormTitikPenataanModal">
                          <i class="ni ni-archive-2"></i>Tambah titik penaatan
                        </base-button>
                      </div>
                      <!-- TABLE HERE -->
                      <template>
                        <div class="content">
                          <div>
                            <el-table :data="newPayloadTmp" row-key="index" header-row-class-name="thead-light"
                              @sort-change="sortChange" @selection-change="selectionChange">
                              <el-table-column min-width="180px" label="Nama Titik Penaatan">
                                <div slot-scope="action">
                                  <b>{{ action.row.spot_name }}</b>
                                </div>
                              </el-table-column>

                              <el-table-column min-width="180px" label="Lintang">
                                <div slot-scope="action">
                                  <b>{{ action.row.latitude }}</b>
                                </div>
                              </el-table-column>

                              <el-table-column min-width="180px" label="Bujur">
                                <div slot-scope="action">
                                  <b>{{ action.row.longitude }}</b>
                                </div>
                              </el-table-column>


                              <el-table-column min-width="180px" label="AKSI">
                                <div slot-scope="action">
                                  <base-button @click="populateUpdateStation(action.row)" type="warning" size="sm" icon>
                                    RUBAH
                                  </base-button>
                                  <base-button @click="willDeleteStation(action.row)" type="danger" size="sm" icon>
                                    HAPUS
                                  </base-button>
                                </div>
                              </el-table-column>
                            </el-table>
                          </div>
                        </div>
                      </template>
                      <!-- END OF TABLE HERE -->
                    </div>
                  </div>
                </div>

                <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                  <b-form role="form" @submit.prevent="handleSubmit(!getStationForUpdate ? submitStation : updateStation)"
                    ref="formStation">
                    <div v-show="showFormPenataanFormModal">
                      <div class="mt-5">
                        <h3 class="text-center mb-3">Identifikasi Sumber Pencemar</h3>
                        <div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Nama Titik Penaatan</label>
                            <div class="col-lg-9">
                              <base-input name="Nama Titik Penaatan" v-model="station.spot_name"
                                :rules="{ required: true }" />
                              <small class="d-block mt--3 text-justify">
                                Nama titik penaatan yang akan didaftarkan
                                untuk SPARING WAJIB sama dengan titik penaatan
                                aktif pada SIMPEL PPA
                              </small>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Lintang</label>
                            <div class="col-lg-9">
                              <base-input name="Lintang" type="text" v-model="station.latitude"
                                :rules="{ required: true, min_value: -90, max_value: 90 }" />
                              <small class="d-block mt--3 text-justify">Contoh: -7.77751</small>
                              <br />
                              <small class="d-block mt--3 text-justify">Titik penaatan yang akan didaftarkan untuk
                                SPARING.</small>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Bujur</label>
                            <div class="col-lg-9">
                              <base-input name="Bujur" type="text" v-model="station.longitude"
                                :rules="{ required: true, min_value: -180, max_value: 180 }" />
                              <small class="d-block mt--3 text-justify">Contoh: 110.37804</small>
                              <br />
                              <small class="d-block mt--3 text-justify">Titik penaatan yang akan didaftarkan untuk
                                SPARING.</small>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Nama Badan Air Penerima Air
                              Limbah</label>
                            <div class="col-lg-9">
                              <base-input name="Nama Badan Air Penerima Air Limbah" v-model="station.waste_receiver"
                                :rules="{ required: true }" />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">DAS</label>
                            <div class="col-lg-9">
                              <base-input name="DAS" v-model="station.das" :rules="{ required: true }" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Jenis teknologi pengolahan air limbah -->
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label mt--2">Jenis Teknologi Pengolahan
                          AirLimbah</label>
                        <div class="col-lg-9">
                          <base-input name="Jenis Teknologi Pengolahan Air Limbah" v-model="station.processing_technique"
                            :rules="{ required: true }" />
                          <small class="d-block mt--3 text-justify">
                            Disesuaikan dengan Izin pembuangan air limbah atau izin
                            lingkungan/AMDAL/UKL UPL/RKL RPL. Contoh: Aerob,
                            Anaerob, dll.
                          </small>
                        </div>
                      </div>

                      <!-- Jenis air limbah -->
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Jenis Air Limbah</label>
                        <div class="col-lg-9">
                          <base-input name="Jenis Air Limbah" v-model="station.waste_type" :rules="{ required: true }" />
                          <small class="d-block mt--3 text-justify">
                            Contoh: air terproduksi, air limbah proses, air limbah
                            lokasi penambangan, dll.
                          </small>
                        </div>
                      </div>

                      <!-- Debit -->
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Debit</label>
                        <div class="col-lg-9 d-flex px-0">
                          <div class="col-6" id="debit">
                            <base-input name="debit" type="number" v-model="station.debit_value"
                              :rules="{ required: true }" />
                            <small class="d-block mt--3 text-justify">Debit Maksimum yang wajib di dalam izin</small>
                          </div>
                          <div class="col-6">
                            <base-input name="Satuan debit" :rules="{ required: true }">
                              <el-select v-model="station.debit_unit">
                                <el-option v-for="(item, index) in [
                                  'm/3 detik',
                                  'm/3 menit',
                                  'm/3 jam',
                                  'm/3 hari',
                                  'm3 bulan',
                                ]" :key="index" :label="item" :value="item" />
                              </el-select>
                            </base-input>
                            <small class="d-block mt--3 text-justify">nama satuan</small>
                          </div>
                        </div>
                      </div>

                      <!-- Kapasitas produksi sesuai izin -->
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Kapasitas Produksi Sesuai Izin</label>
                        <div class="col-lg-9">
                          <base-input name="Kapasitas Produksi Sesuai Izin" type="number"
                            v-model="station.capacity_permit" :rules="{ required: true }" />
                          <small class="d-block mt--3 text-justify">Izin Lingkungan/AMDAL/UKL UPL/RKL RPL</small>
                        </div>
                      </div>

                      <!-- Kapasitas produksi senyatanya -->
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Kapasitas Produksi Senyatanya</label>
                        <div class="col-lg-9">
                          <base-input name="Kapasitas Produksi Senyatanya" type="number" v-model="station.capacity_value"
                            :rules="{ required: true }" />
                          <small class="d-block mt--3 text-justify">
                            Pencatatan logbook bulanan selama 1 atau 2 tahun
                            terakhir. Kapasitas produksi rata-rata bulanan.
                          </small>
                        </div>
                      </div>

                      <!-- Frequensi pembuangan air limbah -->
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Frekuensi Pembuangan Air Limbah</label>
                        <div class="col-lg-9">
                          <base-input name="Per tahun" :rules="{ required: true }">
                            <el-select v-model="station.frequency.type">
                              <el-option label="Terus Menerus/Kontinyu" value="Terus Menerus/Kontinyu"></el-option>
                              <el-option label="Tidak Rutin/Intermiten" value="Tidak Rutin/Intermiten"></el-option>
                            </el-select>
                          </base-input>
                        </div>
                      </div>

                      <hr />
                      <h3 class="text-center mb-3">Persyaratan Teknis</h3>

                      <!-- Detail frekuensi pembuangan air limbah -->
                      <div v-if="station.frequency.type === 'Terus Menerus/Kontinyu'" class="card bg-white border-0">
                        <div class="card-header px-4">
                          Detail Frekuensi Pembuangan Air Limbah
                        </div>
                        <div class="card-body p-4">
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Per hari</label>
                            <div class="col-lg-9">
                              <base-input name="Per hari">
                                <el-select v-model="station.frequency.jam">
                                  <el-option v-for="item in 24" :key="item + ' jam'" :label="item + ' jam'"
                                    :value="item"></el-option>
                                </el-select>
                              </base-input>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Per minggu</label>
                            <div class="col-lg-9">
                              <base-input name="Per minggu">
                                <el-select v-model="station.frequency.hari">
                                  <el-option v-for="item in 7" :key="item + ' hari'" :label="item + ' hari'"
                                    :value="item"></el-option>
                                </el-select>
                              </base-input>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Per tahun</label>
                            <div class="col-lg-9">
                              <base-input name="Per tahun">
                                <el-select v-model="station.frequency.bulan">
                                  <el-option v-for="item in 12" :key="item + ' bulan'" :label="item + ' bulan'"
                                    :value="item"></el-option>
                                </el-select>
                              </base-input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="station.frequency.type === 'Tidak Rutin/Intermiten'" class="card bg-white border-0">
                        <div class="card-header px-4">Detail Frekuensi Pembuangan Air Limbah</div>
                        <div class="card-body p-4">
                          <!-- Latar belakang -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Latar Belakang</label>
                            <div class="col-lg-9">
                              <html-editor v-model="station.frequency.latar_belakang"></html-editor>
                              <small class="d-block mt--3 text-justify">
                                Penjelasan (deskripsi) dilakukan pembuangan air
                                limbah secara intermiten
                              </small>
                            </div>
                          </div>

                          <!-- Detail engineering IPAL -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Detail Engineering IPAL</label>
                            <div class="col-lg-9">
                              <html-editor v-model="station.frequency.detail_eng_ipal"></html-editor>
                            </div>
                          </div>

                          <!-- foto ipal -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Foto IPAL</label>
                            <div class="col-lg-9">
                              <base-input name="Foto IPAL">
                                <file-input :disabled="uploading" accept="image/x-png, image/jpeg, application/pdf"
                                  ref="ipal_file_id"
                                  @change="uploadFileBlob(station.frequency, 'ipal_file_id')"></file-input>
                              </base-input>
                              <small v-if="station.frequency.ipal_file_id" class="d-block mt-3 mb-2 text-justify">
                                <a :href="getBlobsFile(station.frequency.ipal_file_id)" target="_blank"
                                  class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                              </small>
                              <small class="d-block mt-3 text-justify">Bangunan IPAL dan outlet.</small>
                            </div>
                          </div>

                          <!-- perhari -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Per hari</label>
                            <div class="col-lg-9">
                              <base-input name="Per hari">
                                <el-select v-model="station.frequency.jam">
                                  <el-option v-for="item in 24" :key="item + ' jam'" :label="item + ' jam'"
                                    :value="item"></el-option>
                                </el-select>
                              </base-input>
                            </div>
                          </div>

                          <!-- perminggu -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Per minggu</label>
                            <div class="col-lg-9">
                              <base-input name="Per minggu">
                                <el-select v-model="station.frequency.hari">
                                  <el-option v-for="item in 7" :key="item + ' hari'" :label="item + ' hari'"
                                    :value="item"></el-option>
                                </el-select>
                              </base-input>
                            </div>
                          </div>

                          <!-- pertahun -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Per tahun</label>
                            <div class="col-lg-9">
                              <base-input name="Per tahun">
                                <el-select v-model="station.frequency.bulan">
                                  <el-option v-for="item in 12" :key="item + ' bulan'" :label="item + ' bulan'"
                                    :value="item"></el-option>
                                </el-select>
                              </base-input>
                            </div>
                          </div>

                          <!-- metode pengukuran debit -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Metode Pengukuran Debit</label>
                            <div class="col-lg-9">
                              <html-editor v-model="station.frequency.metode_ukur"></html-editor>
                              <small class="d-block mt--3 text-justify">Metode pengukuran debit yang dilakukan secara
                                manual.</small>
                            </div>
                          </div>

                          <!-- Logbook pembuangan air -->
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Logbook Pembuangan Air
                              Limbah</label>
                            <div class="col-lg-9">
                              <base-input name="Logbook">
                                <file-input accept="application/pdf" ref="logbook_file_id"
                                  @change="uploadFileBlob(station.frequency, 'logbook_file_id')"></file-input>
                              </base-input>
                              <small v-if="station.frequency.logbook_file_id" class="d-block mt-3 mb-2 text-justify">
                                <a :href="getBlobsFile(station.frequency.logbook_file_id)" target="_blank"
                                  class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Logger -->
                      <div>
                        <div>
                          <form>
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Lulus Uji Konektifitas</label>
                              <div class="col-lg-9">
                                <base-input name="Lulus Uji Konektifitas">
                                  <el-select v-model="station.tech_requirement.tipe_uji">
                                    <el-option label="Individu" value="Individu"></el-option>
                                    <el-option label="Penyedia Barang dan Jasa Alat Sparing"
                                      value="Penyedia Barang dan Jasa Alat Sparing"></el-option>
                                  </el-select>
                                </base-input>
                                <small class="d-block mt--3 text-justify">
                                  Individu dipilih bagi industri yang memasang dan
                                  mengoperasikan tanpa bekerja sama dengan
                                  penyedia barang/jasa alat sensor dan logger.
                                </small>
                              </div>
                            </div>

                            <!-- Nomor surat lulus uji konektifitas -->
                            <div v-if="station.tech_requirement.tipe_uji === 'Individu'" class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Nomor Surat Lulus Uji
                                Konektivitas</label>
                              <div class="col-lg-9">
                                <base-input name="Brand" v-model="station.tech_requirement.no_surat" />
                                <small class="d-block mt--3 text-justify">Nomor surat Lulus Uji Konektivitas diterbitkan
                                  olehKLHK</small>
                              </div>
                            </div>

                            <!-- Surat keterangan lulus uji konektifitas -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Surat Keterangan Lulus Uji
                                Konektivitas</label>
                              <div class="col-lg-9">
                                <base-input name="Lulus Uji Konektivitas">
                                  <file-input accept="application/pdf" ref="lulus_uji_file_id"
                                    @change="uploadFileBlob(station.tech_requirement, 'lulus_uji_file_id')" />
                                </base-input>
                                <small v-if="station.tech_requirement.lulus_uji_file_id"
                                  class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(station.tech_requirement.lulus_uji_file_id)" target="_blank"
                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                                <small class="d-block mt-3 text-justify">Wajib yang masih berlaku</small>
                              </div>
                            </div>

                            <!-- Nama penyedia barang dan jasa -->
                            <div v-if="station.tech_requirement.tipe_uji === 'Penyedia Barang dan Jasa Alat Sparing'"
                              class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Nama Penyedia Barang dan
                                Jasa</label>
                              <div class="col-lg-9">
                                <base-input name="Brand" v-model="station.tech_requirement.nama_penyedia" />
                                <small class="d-block mt--3 text-justify">Surat Keterangan Lulus Uji Konektivitas.</small>
                              </div>
                            </div>

                            <!-- Kontrak kerja sama -->
                            <div v-if="station.tech_requirement.tipe_uji === 'Penyedia Barang dan Jasa Alat Sparing'"
                              class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Kontrak Kerjasama</label>
                              <div class="col-lg-9">
                                <base-input name="Surat Keterangan Lulus Uji Konektivitas">
                                  <file-input accept="application/pdf" ref="kontak_kerjasama_file_id"
                                    @change="uploadFileBlob(station.tech_requirement, 'kontak_kerjasama_file_id')"></file-input>
                                </base-input>
                                <small v-if="station.tech_requirement.kontak_kerjasama_file_id"
                                  class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(station.tech_requirement.kontak_kerjasama_file_id)"
                                    target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                              </div>
                            </div>

                            <!-- Info Alat -->
                            <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Info Alat</label>
                              <div class="col-lg-9">
                                <base-input name="Per tahun" rules="required">
                                  <el-select v-model="station.tech_requirement.info_alat">
                                    <el-option label="Baru" value="Baru"></el-option>
                                    <el-option label="Lama" value="Lama"></el-option>
                                  </el-select>
                                </base-input>
                                <small class="d-block mt--3 text-justify">
                                  Alat lama adalah sensor yang sudah terpasang
                                  sebelum Permen LH 93/2018.
                                </small>
                              </div>
                            </div>

                            <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Hasil Kalibrasi Saat Awal
                                Pemasangan Alat</label>
                              <div v-if="station.tech_requirement.tipe_uji !== null" class="col-lg-9">
                                <base-input name="Hasil Kalibrasi Saat Awal Pemasangan Alat">
                                  <file-input accept="application/pdf" ref="kalibrasi_awal_file_id" @change="
                                    uploadFileBlob(station.tech_requirement, 'kalibrasi_awal_file_id')
                                    "></file-input>
                                </base-input>
                                <small v-if="station.tech_requirement.kalibrasi_awal_file_id"
                                  class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(station.tech_requirement.kalibrasi_awal_file_id)" target="_blank"
                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                                <small class="d-block mt-3 text-justify">Sertifikat hasil uji kalibrasi.</small>
                              </div>
                            </div>

                            <!-- Hasil kalibrasi alat rutin -->
                            <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Hasil Kalibrasi Alat Rutin</label>
                              <div class="col-lg-9">
                                <base-input name="Hasil Kalibrasi Alat Rutin">
                                  <file-input accept="application/pdf" ref="kalibrasi_rutin_file_id" @change="
                                    uploadFileBlob(station.tech_requirement, 'kalibrasi_rutin_file_id')
                                    "></file-input>
                                </base-input>
                                <small v-if="station.tech_requirement.kalibrasi_rutin_file_id"
                                  class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(station.tech_requirement.kalibrasi_rutin_file_id)"
                                    target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                                <small class="d-block mt-3 text-justify">Sertifikat hasil uji kalibrasi.</small>
                              </div>
                            </div>

                            <!-- Hasil uji kinerja dari labotarium yang ditunjuk oleh KLHK -->
                            <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">
                                Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk
                                Oleh KLHK
                              </label>
                              <div class="col-lg-9">
                                <base-input name="Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk Oleh KLHK">
                                  <file-input accept="application/pdf" ref="kinerja_file_id" @change="
                                    uploadFileBlob(station.tech_requirement, 'kinerja_file_id')
                                    "></file-input>
                                </base-input>
                                <small v-if="station.tech_requirement.kinerja_file_id"
                                  class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(station.tech_requirement.kinerja_file_id)" target="_blank"
                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                                <small class="d-block mt-3 text-justify">
                                  Uji kinerja alat wajib dilaksanakan di
                                  laboratorium yang ditunjuk oleh KLHK cq. Pusat
                                  Standarisasi Lingkungan dan Kehutanan.
                                  Laboratorium dimaksud adalah laboratorium yang
                                  melaksanakan pengujian kinerja alat pemantauan
                                  online air sesuai prosedur dalam petunjuk teknis
                                  Uji Kinerja Alat Pemantauan Online untuk Air
                                  mengacu kepada SNI ISO 15839:2003.
                                </small>
                              </div>
                            </div>

                            <!-- Surat dukungan dari brand sensor -->
                            <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Surat Dukungan Dari Brand
                                Sensor</label>
                              <div class="col-lg-9">
                                <base-input name="Surat Dukungan Dari Brand Sensor">
                                  <file-input accept="application/pdf" ref="dukungan_brand_file_id" @change="
                                    uploadFileBlob(station.tech_requirement, 'dukungan_brand_file_id')
                                    "></file-input>
                                </base-input>
                                <small v-if="station.tech_requirement.dukungan_brand_file_id"
                                  class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(station.tech_requirement.dukungan_brand_file_id)" target="_blank"
                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                                <small class="d-block mt-3 text-justify">Upload Surat Dukungan Dari Brand Sensor</small>
                              </div>
                            </div>

                            <!-- Surat pernyataan -->
                            <div v-if="station.tech_requirement.tipe_uji !== null" class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Surat Pernyataan</label>
                              <div class="col-lg-9">
                                <base-input name="Surat Pernyataan dari Vendor Sensor">
                                  <file-input accept="application/pdf" ref="pernyataan_vendor_file_id" @change="
                                    uploadFileBlob(station.tech_requirement, 'pernyataan_vendor_file_id')
                                    "></file-input>
                                </base-input>
                                <small v-if="station.tech_requirement.pernyataan_vendor_file_id"
                                  class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(station.tech_requirement.pernyataan_vendor_file_id)"
                                    target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                                <small class="d-block mt-3 text-justify">
                                  Khusus untuk perusahaan atau vendor yang merakit
                                  alat sensor,
                                  <b>secara mandiri</b> menyampaikan surat
                                  pernyataan.
                                </small>
                              </div>
                            </div>

                            <!-- ======================================= LOGGER ======================================= -->
                            <hr />
                            <h3 class="text-center mb-3">Logger</h3>

                            <!-- Brand -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Brand</label>
                              <div class="col-lg-9">
                                <base-input name="Brand Logger" v-model="station.logger.brand"
                                  :rules="{ required: true }" />
                                <small class="d-block mt--3 text-justify">
                                  Jika menggunakan data logger Lokal (Rakitan
                                  Lokal), disampaikan spesifikasinya
                                </small>
                              </div>
                            </div>

                            <!-- Model -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Model</label>
                              <div class="col-lg-9">
                                <base-input name="Model" v-model="station.logger.model" :rules="{ required: true }" />
                              </div>
                            </div>

                            <!-- Serial number -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Serial Number</label>
                              <div class="col-lg-9">
                                <base-input name="Serial Number" v-model="station.logger.sn" :rules="{ required: true }" />
                              </div>
                            </div>

                            <!-- Mac Address -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">MAC Address</label>
                              <div class="col-lg-9">
                                <base-input name="MAC Address" v-model="station.logger.mac" :rules="{ required: true }" />
                                <small class="d-block mt--3 text-justify">Contoh: OA:0E:AA:8R:77</small>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <!-- Sensor -->
                      <hr />
                      <h3 class="text-center mb-3">Sensor</h3>

                      <div class="card bg-white border-0">
                        <div class="card-body p-4">
                          <div v-for="(item, index) in station.logger.sensors" :key="item.type">
                            <div class="form-group row">
                              <label class="col-lg-2 col-form-label form-control-label">{{ item.type }}</label>
                              <div class="col-lg-10">
                                <base-checkbox v-model="station.logger.sensors[index].is_active" class="mt-2 mb-3">
                                  {{
                                    (station.logger.sensors[index].is_active == 1
                                    ? "Digunakan"
                                    : "Tidak digunakan")
                                  }}
                                </base-checkbox>
                                <small class="d-block mt--3 text-justify">
                                  Aktifkan checkbox jika sensor digunakan untuk
                                  mengisi data sensor
                                </small>
                              </div>
                            </div>
                            <template v-if="station.logger.sensors[index].is_active">
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label class="col-12 col-form-label form-control-label py-0">Brand</label>
                                  <div class="col-12">
                                    <base-input rules="required" :name="`brand ${station.logger.sensors[index].name}`"
                                      v-model="station.logger.sensors[index].brand" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label class="col-12 col-form-label form-control-label py-0">Probe</label>
                                  <div class="col-12">
                                    <base-input :name="`Probe ${station.logger.sensors[index].name}`" rules="required"
                                      v-model="station.logger.sensors[index].probe" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label class="col-11 col-form-label form-control-label py-0">Brosur</label>
                                  <div class="col-12">
                                    <base-input :name="`Brosur ${station.logger.sensors[index].type}`">
                                      <file-input accept="application/pdf" :ref="'brochure_file_id_' + index"
                                        @change="uploadFileSensorBlob(station.logger.sensors[index],'brochure_file_id',`brochure_file_id_${index}`)" />
                                    </base-input>
                                    <small v-if="station.logger.sensors[index].brochure_file_id
                                      " class="d-block mt-3 text-justify">
                                      <a :href="getBlobsFile(station.logger.sensors[index].brochure_file_id)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10">
                                  <label class="col-11 col-form-label form-control-label px-0">Range Pengukuran
                                    Sensor</label>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input
                                            :name="`Range Pengukuran Sensor ${station.logger.sensors[index].type} Minimal`"
                                            rules="required" type="number" v-model="station.logger.sensors[index].min" />
                                          <small class="d-block mt--4 text-justify">Range Minimum</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input rules="required"
                                            :name="`Range Pengukuran Sensor ${station.logger.sensors[index].type} Maksimal`"
                                            type="number" v-model="station.logger.sensors[index].max" />
                                          <small class="d-block mt--4 text-justify">Range Maximum</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10">
                                  <label class="col-11 col-form-label form-control-label px-0">Baku Mutu Air Limbah Sesuai
                                    Izin</label>
                                  <div v-if="item.type === 'pH'" class="row">
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input :name="`Baku Mutu ${station.logger.sensors[index].type} Minimal`"
                                            rules="required" type="number"
                                            v-model="station.logger.sensors[index].bmal_min" />
                                          <small class="d-block mt--4 text-justify">BMAL Minimum</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input type="number"
                                            :name="`Baku Mutu ${station.logger.sensors[index].type} Maksimal`"
                                            rules="required" v-model="station.logger.sensors[index].bmal_max" />
                                          <small class="d-block mt--4 text-justify">BMAL Maximum</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else-if="item.type === 'Debit'" class="row">
                                    <div class="col-12">
                                      <div class="form-group row">
                                        <div class="col-lg-9">
                                          <base-input type="number" rules="required" name="Debit maksimum"
                                            v-model="station.logger.sensors[index].bmal_max" />
                                          <small class="d-block mt--4 text-justify">Range Maximum</small>
                                        </div>
                                        <div class="col-3">
                                          <base-input name="Satuan sensor debit" rules="required">
                                            <el-select v-model="station.logger.sensors[index].bmal_unit
                                              ">
                                              <el-option v-for="(unit, index) in debitUnit" :key="index" :label="unit"
                                                :value="unit" />
                                            </el-select>
                                          </base-input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else class="row">
                                    <div class="col-12">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input type="number" rules="required"
                                            :name="`${station.logger.sensors[index].type} maksimum`"
                                            v-model="station.logger.sensors[index].bmal_max" />
                                          <small class="d-block mt--4 text-justify">BMAL Maximum</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label class="col-12 col-form-label form-control-label py-0">Metode Pengukuran</label>
                                  <div class="col-12">
                                    <base-input rules="required"
                                      :name="`Metode Pengukuran ${station.logger.sensors[index].type}`"
                                      v-model="station.logger.sensors[index].bmal_measure" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label class="col-12 col-form-label form-control-label py-0">
                                    Jadwal Kalibrasi
                                  </label>
                                  <div class="col-12">
                                    <base-input rules="required"
                                      :name="`Jadwal Kalibrasi ${station.logger.sensors[index].type}`">
                                      <el-select v-model="station.logger.sensors[index].calibration_schedule" filterable>
                                        <el-option v-for="bulan in 60" :key="bulan" :label="`${bulan} Bulan`"
                                          :value="`${bulan}`" />
                                      </el-select>
                                    </base-input>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label class="col-12 col-form-label form-control-label py-0">Tanggal Kalibrasi</label>
                                  <div class="col-12">
                                    <base-input class="m-0" rules="required"
                                      :name="`Tanggal Kalibrasi ${station.logger.sensors[index].type}`">
                                      <flat-picker :config="selects.configs.flatpickr" class="form-control datepicker"
                                        v-model="station.logger.sensors[index].calibration_date
                                          " />
                                    </base-input>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label class="col-12 col-form-label form-control-label py-0">Metode Kalibrasi</label>
                                  <div class="col-12">
                                    <base-input rules="required"
                                      :name="`Metode Kalibrasi ${station.logger.sensors[index].type}`"
                                      v-model="station.logger.sensors[index].calibration_method" />
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                          <base-input :rules="{ required: { allowFalse: false } }" name="Sensor">
                            <base-checkbox v-model="sensorDone">Data sensor sudah benar</base-checkbox>
                          </base-input>
                        </div>
                      </div>

                      <div style="display: flex;justify-content: center;align-items: center;">
                        <button class="btn btn-primary mt-5 w-25" native-type="submit"
                          v-if="!getStationForUpdate">SELESAI</button>
                        <button class="btn btn-warning mt-5 w-25" native-type="submit"
                          v-if="getStationForUpdate">PERBAHARUI</button>
                        <button class="btn btn-danger mt-5 w-25" @click="showFormPenataanFormModal = false">BATAL</button>
                      </div>
                    </div>
                  </b-form>
                </validation-observer>

                <div style="display:flex;justify-content: center;align-items: center;margin-top: 50px;"
                  v-if="!showFormPenataanFormModal && newPayloadTmp.length > 0">
                  <button class="btn btn-primary" @click="finalSubmit">SIMPAN</button>
                  <button class="btn btn-danger">BATAL</button>
                </div>
                <div v-else class="mt-3">
                  <p><b><small>*Tombol Kirim Pendaftaran hanya tersedia jika anda sudah mengisi minimal 1 titik
                        Penaatan</small></b></p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import API from "@/api/base_url.js";
import baseURL from "@/api/base_url_backend.js";
import FileInput from "@/components/Inputs/FileInput";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
} from "element-ui";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import PendaftaranSparingTable from "../../../Tables/PendaftaranSparing.vue";
import BaseHeader from "@/components/BaseHeader";
import SparingTmpTable from "../../../Tables/SparingTmpTable.vue";
import SparingTmpPenaatanTable from "../../../Tables/SparingTmpPenaatan.vue";
import SparingTmpDataLoggerTable from "../../../Tables/SparingTmpDataLoggerTable.vue";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../../Tables/PaginatedTables/clientPaginationMixin";
import moment from "moment";

export default {
  name: "pendaftaranSparing",
  mixins: [clientPaginationMixin],
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    BaseHeader,
    FileInput,
    HtmlEditor,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
  },
  mounted() {
    this.isLoading = false
  },
  created() {
    this.isLoading = true
    this.getDataForOldLater()
  },
  data() {
    return {
     
      showAnnoncementModal: false,
      showFormModal: false,
      showFormPenataanFormModal: false,
      isLoading: false,
      sensorDone: false,
      uploading: false,
      getStationForUpdate: false,
      selects: {
        configs: {
          flatpickr: {
            allowInput: true,
            altFormat: "Y-m-d",
            altInput: false,
            dateFormat: "Y-m-d",
            mode: "single"
          }
        }
      },
      permissionLaterFormExisting: [],
      newPayloadTmp: [],
      selectedRows: [],
      debitUnit: ["m3/detik", "m3/menit", "m3/jam", "m3/hari", "m3/bulan"],
      form_permits: {
        attachment_file_id: "",
        issuing_authority: "",
        no: "",
        publication_date: "",
        expiration_date: ""
      },
      form: {
        permit_id: "",
        waste_source: "",
        file_id: ""
      },
      station: {
        spot_name: "",
        waste_receiver: "",
        das: "",
        longitude: 0,
        latitude: 0,
        processing_technique: "",
        waste_type: "",
        debit_value: 0,
        debit_unit: "",
        capacity_permit: 0,
        capacity_value: 0,
        frequency: {
          bulan: 0,
          detail_eng_ipal: "",
          hari: 0,
          ipal_file_id: "",
          jam: 0,
          latar_belakang: "",
          logbook_file_id: "",
          metode_ukur: "",
          type: ""
        },
        tech_requirement: {
          dukungan_brand_file_id: "",
          info_alat: "",
          kalibrasi_awal_file_id: "",
          kalibrasi_rutin_file_id: "",
          kinerja_file_id: "",
          kontak_kerjasama_file_id: "",
          lulus_uji_file_id: "",
          nama_penyedia: "",
          no_surat: "",
          pernyataan_vendor_file_id: "",
          tipe_uji: ""
        },
        logger: {
          brand: "",
          mac: "",
          model: "",
          sn: "",
          sensors: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: "",
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
          }))
        }
      }
    };
  },
  methods: {

    //------- FUNCTION MODAL --------------------------------
    showFormTitikPenataanModal() {
      this.showFormPenataanFormModal = true;
      this.getStationForUpdate = false;
      // this.resetForm()
    },


    //---------- FUNCTION PERMITS --------------------------------
    createPermissionLater() {
      if (!this.validateForm(this.form_permits)) {
        return;
      }

      Swal.fire({
        title: "Menambahkan Surat Izin?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };


          API.post("permits", this.form_permits, { headers })
            .then(({ data }) => {
              this.resetForm(this.form_permits)
              this.getDataForOldLater()
              this.showFormModal = false;
              Swal.fire("Surat izin ditambahkan", "pendaftaran surat izin berhasil", "success");
            })
            .catch((err) => {
              console.log(err, "ini error");
              Swal.fire("Pendaftaran Gagal", "harap telah mengisi semua data atau periksa koneksi internet anda", "error");
            });
        }
      });
    },

    getDataForOldLater() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("permits", { headers })
        .then(({ data: content }) => {
          if (content.data == null) {
            this.permissionLaterFormExisting = [];
          } else {
            this.permissionLaterFormExisting = content.data;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
    },

    //---------- FUNCTION STATIONS --------------------------------
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    submitStation() {
      Swal.fire({
        title: "Simpan Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "SIMPAN",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {

          let payload = { ...this.station };
          let tmpSensor = []
          let lastIndex = this.newPayloadTmp.length > 0 ? this.newPayloadTmp[this.newPayloadTmp.length - 1].index : 0;

          // if (payload.frequency.type == "Terus Menerus/Kontinyu") {
          //   delete payload.frequency.detail_eng_ipal;
          //   delete payload.frequency.latar_belakang;
          //   delete payload.frequency.ipal_file_id;
          //   delete payload.frequency.metode_ukur;
          //   delete payload.frequency.logbook_file_id;
          // }

          // if (payload.tech_requirement.tipe_uji == "Individu") {
          //   delete payload.tech_requirement.nama_penyedia;
          //   delete payload.tech_requirement.kontak_kerjasama_file_id;
          // }
          // else if (payload.tech_requirement.tipe_uji == "Penyedia Barang dan Jasa Alat Sparing") {
          //   delete payload.tech_requirement.no_surat;
          //   delete payload.tech_requirement.nama_penyedia;
          // }
          

          for (let i = 0; i < payload.logger.sensors.length; i++) {
            tmpSensor.push({
              bmal_max: parseFloat(+payload.logger.sensors[i].bmal_max),
              bmal_measure: payload.logger.sensors[i].bmal_measure,
              bmal_min: parseFloat(+payload.logger.sensors[i].bmal_min),
              bmal_unit: payload.logger.sensors[i].bmal_unit,
              brand: payload.logger.sensors[i].brand,
              brochure_file_id: payload.logger.sensors[i].brochure_file_id,
              calibration_date: this.formatDatePayload(payload.logger.sensors[i].calibration_date),
              calibration_method: payload.logger.sensors[i].calibration_method,
              calibration_schedule: +payload.logger.sensors[i].calibration_schedule,
              is_active: payload.logger.sensors[i].is_active ? true : false,
              max: parseFloat(+payload.logger.sensors[i].max),
              min: parseFloat(+payload.logger.sensors[i].min),
              probe: payload.logger.sensors[i].probe,
              type: payload.logger.sensors[i].type
            })
          }

          payload.index = ++lastIndex;
          payload.logger.sensors = tmpSensor
          payload.debit_value = parseFloat(payload.debit_value)
          payload.capacity_value = parseFloat(payload.capacity_value)
          payload.longitude = parseFloat(payload.longitude)
          payload.latitude = parseFloat(payload.latitude)
          payload.debit_value = parseFloat(payload.debit_value)
          payload.capacity_permit = parseFloat(payload.capacity_permit)
          payload.frequency.bulan = parseFloat(payload.frequency.bulan)
          payload.frequency.hari = parseFloat(payload.frequency.hari)
          payload.frequency.jam = parseFloat(payload.frequency.jam)

          this.newPayloadTmp.push(payload);

          this.showFormPenataanFormModal = false;

          this.resetFormStation()

        }
      })
    },

    populateUpdateStation(row) {
      this.showFormPenataanFormModal = true
      this.getStationForUpdate = true
      this.station = { ...row }
      console.log(row)
    },

    updateStation() {

      const index = this.newPayloadTmp.findIndex(item => {
        return item.index === this.station.index;
      });

      Swal.fire({
        title: "Perbaharui Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          // this.newPayloadTmp[index] = { ...this.station }
          this.$set(this.newPayloadTmp, index, { ...this.station });
          this.showFormPenataanFormModal = false;
          this.resetFormStation()
        }
      })
    },

    willDeleteStation(row) {
      Swal.fire({
        title: `Hapus Titik Penaatan ${row.spot_name} ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          const index = this.newPayloadTmp.findIndex(item => {
            return item.index === this.station.index;
          });
          if (index >= 0) {
            this.newPayloadTmp.splice(index, 1);
          }

          // this.resetFormStation()
          this.showFormPenataanFormModal = false;

          Swal.fire({
            title: "Berhasil Menghapus",
            text: "Titik Penaatan telah dihapus dari Pendaftaran SPARING",
            icon: "success",
          });
        }
      });
    },
    finalSubmit() {
      const modifiedPayload = this.newPayloadTmp.map(station => {
        // Iterate through sensors array in each station
        const modifiedSensors = station.logger.sensors.map(sensor => {
            // Convert is_active from boolean to number
            return {
                ...sensor,
                is_active: sensor.is_active ? 1 : 0
            };
        });

        // Return the modified station object
        return {
            ...station,
            waste_source:this.form.waste_source,
            logger: {
                ...station.logger,
                sensors: modifiedSensors
            }
        };
      });
      let payload = {
        detail: {
          permit_id: this.form.permit_id,
          total_station: this.newPayloadTmp.length
        },
        station: modifiedPayload
      }
      Swal.fire({
        title: `Mendaftarkan SPARING?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "DAFTAR",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.form.permit_id) {
            Swal.fire({
            title: "Nomor Surat wajib di isi",
            text: "Pastikan anda mengisi nomor surat dengan memilih yang sudah ada atau membuat permohonan surat baru",
            icon: "error",
          });
          } else if (!this.form.waste_source) {
            Swal.fire({
            title: "Sumber Air Limbah wajib di isi",
            text: "Pastikan anda mengisi data sumber air limbah",
            icon: "error",
            })
          } else if (!this.form.permit_id && !this.form.waste_source) {
            Swal.fire({
            title: "Nomor Surat dan Sumber Air Limbah wajib di isi",
            text: "Pastikan anda melengkapi data",
            icon: "error",
            })
          } else {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };


            API.post(`sparing`, payload, { headers })
            .then(({ data: content }) => {
              console.log(content,'BERHASIL');
              this.newPayloadTmp = []
              this.$router.push('/sparing')
                Swal.fire({
                title: "Berhasil Mendaftar",
                text: "Admin SPARING akan meninjau permintaan pendaftaran SPARING anda",
                icon: "success",
              });

            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Pendaftaran Gagal", "gagal mendaftarkan", "error");
            });
          }
        }
      });
    },


    //---------HELPER FUNCTION --------------------------------

    //VALIDATION FUNCTION --------------------------------
    validateForm(formData) {
      let isValid = true;

      // Periksa setiap properti dalam objek formData
      for (const key in formData) {
        if (!formData[key]) {
          // Jika ada properti yang kosong, tandai formulir sebagai tidak valid
          isValid = false;
        }
      }

      // Jika formulir tidak valid, tampilkan pesan kesalahan
      if (!isValid) {
        Swal.fire("Data belum lengkap", "Pastikan Anda melengkapi semua form", "error");
      }

      // Kembalikan status validasi formulir
      return isValid;
    },
    goBack() {
      this.$router.push("/sparing");
    },
    uploadFileBlob(form, field) {

      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
    },
    uploadFileSensorBlob(form,field,ref) {

      // ${defaults.baseURL}/file
      const fileInput = this.$refs[ref][0];
      
      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          console.log(this.$set(form, field, value))
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
    },
    resetForm(formData) {
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          if (typeof formData[key] === 'object' && formData[key] !== null) {
            if (formData[key].hasOwnProperty('path')) {
              formData[key].path = "";
            } else if (formData[key].hasOwnProperty('file')) {
              formData[key].file = null;
            } else {
              this.resetForm(formData[key]); // Panggil secara rekursif untuk objek dalamnya
            }
          } else {
            formData[key] = ""; // Atur ulang nilai properti jika bukan objek
          }
        }
      }
    },
    resetFormStation() {
      this.station = {
        spot_name: "",
        waste_receiver: "",
        das: "",
        longitude: 0,
        latitude: 0,
        processing_technique: "",
        waste_type: "",
        debit_value: 0,
        debit_unit: "",
        capacity_permit: 0,
        capacity_value: 0,
        frequency: {
          bulan: 0,
          detail_eng_ipal: "",
          hari: 0,
          ipal_file_id: "",
          jam: 0,
          latar_belakang: "",
          logbook_file_id: "",
          metode_ukur: "",
          type: ""
        },
        tech_requirement: {
          dukungan_brand_file_id: "",
          info_alat: "",
          kalibrasi_awal_file_id: "",
          kalibrasi_rutin_file_id: "",
          kinerja_file_id: "",
          kontak_kerjasama_file_id: "",
          lulus_uji_file_id: "",
          nama_penyedia: "",
          no_surat: "",
          pernyataan_vendor_file_id: "",
          tipe_uji: ""
        },
        logger: {
          brand: "",
          mac: "",
          model: "",
          sn: "",
          sensors: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: "",
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
          }))
        }
      };
      this.resetAllFileInputs([
        'ipal_file_id',
        'logbook_file_id',
        'dukungan_brand_file_id',
        'kalibrasi_awal_file_id',
        'kalibrasi_rutin_file_id',
        'kinerja_file_id',
        'kontak_kerjasama_file_id',
        'lulus_uji_file_id',
        'pernyataan_vendor_file_id'
      ]);
    },
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDatePayload(val) {
      const time = moment(val).format('YYYY-MM-DD');
      return time;
    },
    
    resetFileInput(ref) {
      if (this.$refs[ref]) {
        // this.$refs.lulus_uji_file_id.files = []
        this.$refs[ref].files = []
        const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
        inputElement.value = ''
      }
    },
    resetAllFileInputs(refs) {
      refs.forEach(ref => this.resetFileInput(ref));
    }
  },
  watch: {
    // wizard one

  },
  computed: {

  },
};
</script>
<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: flex-start;
}

.myshadow {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.step-progress .bar {
  background-color: #1b674c !important;
}

.step-button {
  display: inline-block;
  font-weight: 600;
  color: #1b674c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.step-button-next {
  background-color: #1b674c !important;
  color: #fff;
}

.step-pills .step-item.active {
  border: 1px solid #1b674c !important;
}

.step-pills .step-item {
  margin-right: 5px;
}

.step-item {
  position: relative;
  width: 200px;
  height: 100px;
}

.tabLabel {
  position: absolute;
  bottom: 10px;
  left: 10px;
  line-height: 17px;
}

.popup-modal-background {
  background: #fff;
}

.mdl-contentpopup {
  background: #fff;
  overflow-y: auto;
  max-height: 90%;
}
</style>
